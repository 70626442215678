function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;
    formData.append(parentKey, value);
  }
}

export function jsonToFormData(data) {
  const formData = new FormData();
  buildFormData(formData, data);
  console.log(formData);
  return formData;
}

export const downloadPDF = (url, filename) => {
  fetch(url).then(async function (t) {
    const b = await t.blob();
    let a = document.createElement("a");
    a.href = URL.createObjectURL(b);
    a.setAttribute("download", filename?.split(" ").join("-"));
    a.click();
  });
};
