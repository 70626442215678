import React from "react";
import "./assets/scss/style";
import "./App.css";
// import CookieConsent from "react-cookie-consent";
import { ToastContainer } from "react-toastify";
import Routes from "./routes/routes";

const CloseButton = ({ closeToast }) => (
  <i onClick={closeToast} className="fa fa-close notifications-close" />
);

function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}hq
         closeButton={<CloseButton />}
      />
      <Routes />
      {/* <CookieConsent
        location="bottom"
        buttonText="Accept"
        enableDeclineButton
        declineButtonText="Decline"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent> */}
    </>
  );
}

export default App;
