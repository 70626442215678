import React, { Component } from "react";
import { Passers } from "prop-passer";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";

class SocialShare extends Component {
  render() {
    const { url, title, size = "2.3rem" } = this.props;

    const ShareList = Passers({
      url,
      className: "network__share-button",
    })({
      className: "network cursor-pointer hover transition--default",
      title: `Share ${String(url)}`,
    })("li");

    return (
      <ShareList>
        <FacebookShareButton
          url={String(url)}
          quote={title}
          hashtag={"#jobs"}
          className="social-icon fb"
        >
          <FacebookIcon size={size} />
        </FacebookShareButton>

        <TwitterShareButton
          url={String(url)}
          title={title}
          hashtags={["jobs"]}
          className="social-icon twitter"
        >
          <TwitterIcon size={size} />
        </TwitterShareButton>

        <LinkedinShareButton
          url={String(url)}
          title={title}
          hashtags={["jobs"]}
          className="social-icon linkedin"
        >
          <LinkedinIcon size={size} />
        </LinkedinShareButton>
      </ShareList>
    );
  }
}

export default SocialShare;
