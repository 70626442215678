import React from "react";
import { Row, Col } from "reactstrap";
import { HashLink as Link } from "react-router-hash-link";

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="container-fluid">
        <div className="mb-4">
          <Link to={{ pathname: "/", state: "home" }}>
            <img
              src="/assets/images/CloudDrove.png"
              className="img-fluid footer-logo"
              alt="footer logo"
              width="170"
              height="36"
            />
          </Link>
        </div>
        <Row className="mb-5">
          <Col md="6">
            <div className="foot-head">
              <h6>
                We Listen <br />
                You Prosper
              </h6>
            </div>
            <span className="about-footer">
              Ready to Shift to the DevOps Culture? Talk to our experts at Cloud
              Drove to understand in detail the process and benefits.
            </span>
            {/* <div className="subscribe">
							
						<ul className="list-unstyled">
									{Object.entries(formErrors || {}).map(([prop, value]) => {
										return (
											<li className='text-danger fw-bold' key={prop}>
												{value}
											</li>
										);
									})}
								</ul>
							<Form onSubmit={handlsubscribeSubmit}>
								<FormGroup>
									<Input type="email" name="email" id="exampleEmail" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Subscribe us for Newsletter" required />
								</FormGroup>
								<Button type="submit" className="subscribe-btn"><i className="fa fa-angle-right"></i></Button>
							</Form>
						</div> */}
          </Col>
          <Col md="5" className="offset-md-1">
            <Row>
              <Col md="6">
                <h5 className="menu-title">Site</h5>
                <ul className="list-inline foot-menu">
                  <li>
                    <Link to={{ pathname: "/", hash: "#services" }}>
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "/about" }}>About Us</Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "/how-we-work" }}>How We Work</Link>
                  </li>
                  <li>
                    <Link
                      to={{ pathname: "https://blog.clouddrove.com/" }}
                      target="_blank"
                    >
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "/career" }}>Career</Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "/about", hash: "#contact" }}>
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "/privacy-policy" }}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "/terms-of-services" }}>
                      Terms and Conditions
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col md="6">
                <h5 className="menu-title">Contact</h5>
                <div className="mail_user mb-4">
                  <span>Write us at</span>
                  <span>hello@clouddrove.com</span>
                </div>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <Link
                      to={{ pathname: "https://www.upwork.com/ag/clouddrove/" }}
                      target="_blank"
                    >
                      <img
                        src="/assets/images/upwork.png"
                        className="img-fluid"
                        alt="upwork"
                        width="38"
                        height="38"
                      />
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to={{ pathname: "https://github.com/clouddrove" }}
                      target="_blank"
                    >
                      <img
                        src="/assets/images/github.png"
                        className="img-fluid"
                        alt="upwork"
                        width="38"
                        height="38"
                      />
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to={{
                        pathname: "https://ca.linkedin.com/company/clouddrove",
                      }}
                      target="_blank"
                    >
                      <img
                        src="/assets/images/linkedIn.png"
                        className="img-fluid"
                        alt="upwork"
                        width="35"
                        height="35"
                      />
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to={{
                        pathname: "https://twitter.com/clouddrove?lang=en",
                      }}
                      target="_blank"
                    >
                      <img
                        src="/assets/images/twitter_icon.png"
                        className="img-fluid"
                        alt="twitter"
                        width="35"
                        height="35"
                      />
                    </Link>
                  </li>
                  {/* <li className="list-inline-item">
											<Link to={{pathname: "https://www.instagram.com/cloud_drove/"}} target="_blank">
												<img src="/assets/images/insta.png" className="img-fluid" alt="upwork" width="35" height="35" />
											</Link>
										</li>
										<li className="list-inline-item">
											<Link to={{pathname: "https://clutch.co/profile/clouddrove"}} target="_blank">
												<img src="/assets/images/clutch.png" className="img-fluid" alt="upwork" width="30" height="30" />
											</Link>
										</li> */}
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <span>Drop your email and stay updated with our newsletters</span> */}
      </div>
    </footer>
  );
};

export default Footer;
