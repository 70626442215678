import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "../common/scrollTop";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Home from "../containers/Home/home";
import About from "../containers/About/about";
import Career from "../containers/Career/career";
import JobDetail from "../containers/JobDetail/jobDetail";
import JobApply from "../containers/JobApply/jobApply";
import PrivacyPolicy from "../containers/PrivacyPolicy/privacyPolicy";
import TermsCondition from "../containers/TermsCondition/termsCondition";
import HowWework from "../containers/HowWework/HowWework";

const Routes = () => {
  return (
    <div className="main-wrapper">
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/career" component={Career} />
          <Route exact path="/career/:slug" component={JobDetail} />
          <Route exact path="/job-apply/:slug" component={JobApply} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-of-services" component={TermsCondition} />
          <Route exact path="/how-we-work" component={HowWework} />
          <Route exact path="*" component={Home} />
        </Switch>
        <ScrollToTop />
        <Footer />
      </Router>
    </div>
  );
};

export default Routes;
