import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  TabContent,
  TabPane,
  Button,
  Media,
  Modal,
  ModalBody,
} from "reactstrap";
import classnames from "classnames";
import Flickity from "react-flickity-component";
import { baseURL } from "./../../config";
import { getStartupAPI, getBlogsAPI } from "./../../services/user";
import { downloadPDF } from "../../utils";

const Home = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [startup, setStartup] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [modaltextcasestudy, setModaltext] = useState("");
  const [clientnamecasestudy, setClientname] = useState("");
  const [clientimagecasestudy, setClientimage] = useState("");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  //For Modal
  const [modal, setModal] = useState(false);

  function toggle1(modelumber) {
    switch (modelumber) {
      case 1:
        setModaltext(
          "<b>Project requirements</b> <br/> Our client was facing several critical challenges in their infrastructure. As the pace of business increased, these challenges were no longer sustainable. To meet the demands and gain scalability, reduced disruption, and data delivery, speed was key. They needed to modernize their infrastructure. <br/> <br/> <b>Solution: </b><br/>CloudDrove team dockerize the application and moved the app from AWS EC2 to Kubernetes. Our team was responsible for setting up the build and deployment automation with Terraform/Ansible. We set up CI/CD with Jenkins and make use of a wide range of plugins available to set up smooth developer friendly workflows.			Utilized Grafana & Prometheus to monitor the performance environment instances for operational and performance metrics during load testing.	We supported them to manage Compliance and Audit of ISO 27001, SOC 2 standards"
        );
        setClientname("ManagingLife");
        setClientimage("/assets/images/ManagingLife.png");
        break;
      case 2:
        setModaltext(
          "<b>Project Requirements</b><br/> Our client was looking for an expert to design the architecture and lead their DevOps team. The current system was facing a lot of issues due to zero automation (everything was manual and very unstable). They wanted to move to a better infrastructure with Kubernetes and create handle 10K users per min. <br/><br/>	<b>Solution:</b> <br/> CloudDrove solution architects prepared a highly available & secure AWS architecture using the AWS well-architectured framework. We prepared MGT & EKS clusters by using terraform for migrating infrastructure to Kubernetes Jenkins, monitoring, logging deployed on MGT Cluster, which is managed and accessed by DevOps.	Our team set up a fully automated CI/CD by using GitLab & Jenkins which also built scan and push images to ECR, and deployed on multiple environments based on the requirements	Infrastructure pipelines were set up using gitops mythology Migrated 30+ microservices to AWS EKS Setup 24*7 monitoring & Alerting system		Cloud Drove NOC Team (L1) offered 24*7 support	<br/><br/> <b>Result: </b> <br/> The website is smooth, cost-effective, autoscaled based on traffic, and can easily have 10K users per min Management of level 1 alerts made DevOps life easier Deployment became easier and 3 times faster, currently they are able to deploy more than 5 times a day"
        );
        setClientname("Almatar");
        setClientimage("/assets/images/Almatar.png");
        break;
      case 3:
        setModaltext(
          "<b>Project Requirements</b><br/> Herosport is US based start-up and after gaining a significant place in the market they approached us with a requirement of a more scalable infrastructure with high-load capability. The expected volume was 7000-10000 users/ per minute. <br/><br/>	<b>Solution:</b> <br/> As CloudDrove we planned and designed an architecture on AWS. Made the app containerized by Docker. Deployed the app with Hashi Crop and Terraform. Used Hashi corp Packer to create and manage the AMI's and automated the whole AMI creation process using Jenkins jobs. Built scripts for scheduling EC2 auto-scaling, so the infrastructure was capable of using from 1 virtual machine to 3 depending on the user volume.	<br/><br/> <b>Result: </b> <br/> HeroSport increased its product reliability and scalability, while lowering its operating costs. Now it can easily perform up to 15K users per minute."
        );
        setClientname("HeroSports");
        setClientimage("/assets/images/HeroSports.png");
        break;
      case 4:
        setModaltext(
          "CloudDrove makes your cloud adoption journey security centric, defined infrastructure towards a structured with a risk-mitigated approach to address your migration challenges. Reduce application downtime with Terraform and ensure uninterrupted transition"
        );
        setClientname("Cloud Migration");
        setClientimage("/assets/images/CloudMigration.png");
        break;
      case 5:
        setModaltext(
          "Work on Next Gen Dockerized Dev Environments driven by Automated and Self Serve based catalogue solutions. Hasselfree onboarding of new developers without bleeding on deadlines. "
        );
        setClientname("Dev Environments");
        setClientimage("/assets/images/Dev_Enviroinment.png");
        break;
      case 6:
        setModaltext(
          "Facilitate rapid Software Development cycle with Dockerization for continuous development and testing. Aim for defectless releases with short release cycles. Create a dedicated pipeline to remove manual error and enable you for a rapid iteration so that you can focus on product release."
        );
        setClientname("Release Engineering");
        setClientimage("/assets/images/ReleaseEgineering.png");
        break;
      case 7:
        setModaltext(
          "Deploy software on multiple environments with zero downtime. Automated deployment will let you deploy softwares in one click, giving you the freedom of release as per your requirement"
        );
        setClientname("Automated Deployments");
        setClientimage("/assets/images/AutomatedDeployments.png");
        break;
      case 8:
        setModaltext(
          "Monitor meaningful alerts to manage Engineers only when your system requires it. Site Reliability Engineering will also let you maintain and establish (SLIs) service level indicators (SLAs) agreements and (SLOs) objectives. "
        );
        setClientname("Site Reliability Engineering");
        setClientimage("/assets/images/SiteReliabilityEngineering.png");
        break;
      case 9:
        setModaltext(
          "To avoid unpleasant events in realtime, predict your application’s performance and behavior in a simulated, real-life environment. Before the launch of your product, test your app to its limit so that you are always aware of your system’s endurance. "
        );
        setClientname("Load Testing");
        setClientimage("/assets/images/LoadTesting.png");
        break;
      case 10:
        setModaltext(
          "Safeguard your online property from threats. Remediate and identify vulnerable code and system weaknesses before they become a disastrous issue. CloudDrove Security & Compliance ensures that diligence and shield is underpinned in every part of the development. "
        );
        setClientname("Security and Compliance");
        setClientimage("/assets/images/SecurityandCompliance.png");
        break;
      case 11:
        setModaltext(
          "CloudDrove DevSecOps analyse every element of your enterprise to consider and analyze all-any weak spots. Enabling you to bring together proficient individuals from across different technical disciplines to enhance your existing security processes"
        );
        setClientname("Gap Assessments");
        setClientimage("/assets/images/GapAssessments.png");
        break;
      default:
    }
    setModal(!modal);
  }

  // const flickityOptions = {
  //   initialIndex: 1,
  //   wrapAround: true,
  //   prevNextButtons: false,
  //   autoPlay: 3000,
  //   cellAlign: "left",
  //   resize: true,
  //   pauseAutoPlayOnHover: true,
  // };
  const flickityOptions1 = {
    initialIndex: 1,
    wrapAround: true,
    prevNextButtons: false,
    autoPlay: true,
    cellAlign: "left",
    resize: true,
  };
  const flickityOptions2 = {
    wrapAround: true,
    prevNextButtons: false,
    pageDots: false,
    autoPlay: 3000,
    cellAlign: "left",
    resize: true,
  };

  useEffect(() => {
    loadStartup();
    loadBlogs();
  }, []);

  const loadStartup = async () => {
    try {
      const resp = await getStartupAPI();
      setStartup(resp.data.response[0] || null);
    } catch (err) {
      const { response } = err;
      console.log("err=>", response);
    }
  };

  const loadBlogs = async () => {
    try {
      const resp = await getBlogsAPI();
      setBlogs(resp.data.response || []);
    } catch (err) {
      const { response } = err;
      console.log("err=>", response);
    }
  };

  return (
    <>
      <div className="section_one">
        <div className="container-fluid pe-0">
          <Row>
            <Col
              sm="6"
              md="5"
              className="align-self-center wow fadeInLeft"
              data-wow-duration="2s"
            >
              <div className="head-content">
                <h2>
                  Design, deployment, and management
                  <br /> partner for your cloud
                </h2>
                <p>
                  Adopt powerful cloud solutions to introduce better efficiency,
                  increase revenues and build a scalable business.
                </p>
                <NavLink href="/about#contact" className="btn-gradient-primary">
                  Book a Call
                </NavLink>
              </div>
            </Col>
            <Col
              sm="6"
              md="7"
              className="pos-right pe-0 wow fadeInRight"
              data-wow-duration="2s"
            >
              <img
                src="/assets/images/home-1.png"
                id="home-banner"
                className="img-fluid w-100"
                alt="home base"
                width="757"
                height="598"
              />
            </Col>
          </Row>
        </div>
      </div>
      <div className="section_two">
        <div className="container-fluid">
          <div className="mb-5">
            <Row className="eq-height">
              <Col md="6" className="wow fadeInUp" data-wow-duration="2s">
                <div className="tab-card">
                  <span className="border-orange"></span>
                  <p>
                    360 Degree Solution <br /> with distinct supervision 24/7
                  </p>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        What
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        How
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        When
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "4" })}
                        onClick={() => {
                          toggle("4");
                        }}
                      >
                        Why
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "5" })}
                        onClick={() => {
                          toggle("5");
                        }}
                      >
                        From
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "6" })}
                        onClick={() => {
                          toggle("6");
                        }}
                      >
                        To
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
              <Col
                md="5"
                className="offset-md-1 wow fadeInRight"
                data-wow-duration="2s"
              >
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <div className="tab-card-content">
                      <h3>What does CloudDrove do?</h3>
                      <p className="line-break">
                        CloudDrove offers a range of services that commit
                        hassle-free cloud management. Our expertise includes
                        assistance with infrastructure automation, docker &
                        container orchestration, performance engineering, and so
                        on.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="tab-card-content">
                      <h3>How can CloudDrove help your business?</h3>
                      <p className="line-break">
                        CloudDrove eases your cloud journey by streamlining the
                        processes of development and deployment. Our experts
                        help you improve efficiency, scale revenue, and achieve
                        your business goals faster.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="3">
                    <div className="tab-card-content">
                      <h3>
                        When is the right time to get CloudDrove on board?
                      </h3>
                      <p className="line-break">
                        Irrespective of the stage or size of your startup or
                        enterprise, our experts help you draw benefits of the
                        cloud through its management, optimization, and
                        automation; along with other cloud solutions.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="4">
                    <div className="tab-card-content">
                      <h3>Why do you need CloudDrove?</h3>
                      <p className="line-break">
                        CloudDrove assures your business of the best solutions
                        and practices by partnering with your teams to help them
                        navigate through all the challenges around the cloud.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="5">
                    <div className="tab-card-content">
                      <h3>
                        From which locations can you partner with CloudDrove?
                      </h3>
                      <p className="line-break">
                        CloudDrove is a global cloud solution expert focussing
                        on business outcomes for its clients all over the world.
                        With teams spread over three continents, we are
                        available around the clock.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="6">
                    <div className="tab-card-content">
                      <h3>Which businesses does CloudDrove Cater to?</h3>
                      <p className="line-break">
                        Our team of experienced professionals understand your
                        industry and its distinct challenges. CloudDrove works
                        with every business that is on its cloud journey and
                        offers customized solutions.
                      </p>
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </div>
          <TabContent
            activeTab={activeTab}
            className="wow fadeInUp"
            data-wow-duration="2s"
          >
            <TabPane tabId="1">
              <Row>
                <Col md="6">
                  <img
                    src="/assets/images/org-1.png"
                    className="img-fluid w-100"
                    alt="organization"
                    width="645"
                    height="322"
                  />
                </Col>
                <Col md="5" className="offset-md-1 align-self-center">
                  <p>
                    CloudDrove helps organizations utilize the best out of the
                    cloud while keeping DevOps at the center of the process.
                  </p>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col md="6">
                  <img
                    src="/assets/images/org-2.png"
                    className="img-fluid w-100"
                    alt="organization"
                    width="645"
                    height="322"
                  />
                </Col>
                <Col md="5" className="offset-md-1 align-self-center">
                  <p>
                    Partner with us for designing, managing, and implementing
                    cloud solutions that ensure continual growth.
                  </p>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col md="6">
                  <img
                    src="/assets/images/org-3.png"
                    className="img-fluid w-100"
                    alt="organization"
                    width="645"
                    height="322"
                  />
                </Col>
                <Col md="5" className="offset-md-1 align-self-center">
                  <p>
                    If you want to move some or probably all of your systems to
                    the cloud, CloudDrove is your committed collaborator. Want
                    to experience seamless scalability, uncompromised security,
                    and better revenue streams? The right time is now.
                  </p>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="4">
              <Row>
                <Col md="6">
                  <img
                    src="/assets/images/org-4.png"
                    className="img-fluid w-100"
                    alt="organization"
                    width="645"
                    height="322"
                  />
                </Col>
                <Col md="5" className="offset-md-1 align-self-center">
                  <p>
                    Our experts build strategies keeping every organization’s
                    specific needs at the forefront. With our proficiency across
                    several cloud offerings, CloudDrove is the right partner to
                    successfully drive your business towards digital
                    transformation.
                  </p>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="5">
              <Row>
                <Col md="6">
                  <img
                    src="/assets/images/org-1.png"
                    className="img-fluid w-100"
                    alt="organization"
                    width="645"
                    height="322"
                  />
                </Col>
                <Col md="5" className="offset-md-1 align-self-center">
                  <p>
                    CloudDrove is currently in business with companies around
                    the world and is rapidly establishing its footprints in
                    every industry.
                  </p>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="6">
              <Row>
                <Col md="6">
                  <img
                    src="/assets/images/org-2.png"
                    className="img-fluid w-100"
                    alt="organization"
                    width="645"
                    height="322"
                  />
                </Col>
                <Col md="5" className="offset-md-1 align-self-center">
                  <p>
                    From e-commerce to sports and health to fintech, CloudDrove
                    has partnered with several businesses across the globe to
                    drive their cloud journey.
                  </p>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </div>
      <div className="section-three-one wow fadeInUp" data-wow-duration="2s">
        <div className="container-fluid">
          <Row className="mb-5">
            <Col md="6">
              <div className="our-partner">
                <div className="media">
                  <div className="media-icon align-self-center me-3">
                    <img
                      src="/assets/images/partner-icon.png"
                      className="img-fluid"
                      alt="partner icon"
                      width="100"
                      height="100"
                    />
                  </div>
                  <div className="media-body">
                    <h6>Our Partners</h6>
                    <p className="mb-0">
                      The benefits of adopting a DevOps methodology are
                      numerous, and selecting the proper managed DevOps partners
                      is a crucial part of the transformation
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="gx-5">
            <Col md="3" sm="6">
              <div className="client-card">
                <div className="img-wrap">
                  <img
                    src="/assets/images/aws.png"
                    className="img-fluid"
                    alt="aws icon"
                    width="100"
                    height="100"
                  />
                </div>
                <span className="d-block">Amazon Web Services</span>
              </div>
            </Col>
            <Col md="3" sm="6">
              <div className="client-card">
                <div className="img-wrap">
                  <img
                    src="/assets/images/icon-2.png"
                    className="img-fluid"
                    alt="aws icon"
                    width="100"
                    height="100"
                  />
                </div>
                <span className="d-block">Digital Ocean</span>
              </div>
            </Col>
            <Col md="3" sm="6">
              <div className="client-card">
                <div className="img-wrap">
                  <img
                    src="/assets/images/microsoft.png"
                    className="img-fluid"
                    alt="aws icon"
                    width="100"
                    height="100"
                  />
                </div>
                <span className="d-block">Microsoft</span>
              </div>
            </Col>
            <Col md="3" sm="6">
              <div className="client-card">
                <div className="img-wrap">
                  <img
                    src="/assets/images/icon-4.png"
                    className="img-fluid"
                    alt="aws icon"
                    width="100"
                    height="100"
                  />
                </div>
                <span className="d-block">Datadog</span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* <div className="section-three wow fadeInUp">
        <div className="container-fluid">
          <div className="section-title" data-wow-duration="2s">
            <span className="border-gray"></span>
            <h3>Case Studies</h3>
            <p>
              Few of our celebrated Case <br /> Studies that we would like you
              to see.
            </p>
          </div>
        </div>

        <div className="casestudy-block">
          <Flickity
            className={"caseStudy-slider"}
            data-wow-duration="0s"
            elementType={"div"}
            options={flickityOptions}
            disableImagesLoaded={false}
            reloadOnUpdate
            static
            data-flickity='{ "initialIndex": 0 }'
          >
            <Col md="4">
              <div className="study-card icon-1" onClick={() => toggle1(1)}>
                <div className="d-flex justify-content-between m_b_4">
                  <div className="count">1</div>
                  <span className="date">28 Dec 2019</span>
                </div>
                <div className="mb-4">
                  <p>Case Study: ManagingLife.com</p>
                  <h3>
                    Critical <br />
                    Infrastructure Challanges
                  </h3>
                </div>
                <span className="country">Canada</span>
              </div>
            </Col>
            <Col md="4">
              <div className="study-card icon-2" onClick={() => toggle1(2)}>
                <div className="d-flex justify-content-between m_b_4">
                  <div className="count">2</div>
                  <span className="date">22 Feb 2018</span>
                </div>
                <div className="mb-4">
                  <p>Case Study: Almatar.com</p>
                  <h3>
                    Automation <br /> and <br /> Architecture
                  </h3>
                </div>
                <span className="country">Canada</span>
              </div>
            </Col>
            <Col md="4">
              <div className="study-card icon-3" onClick={() => toggle1(3)}>
                <div className="d-flex justify-content-between m_b_4">
                  <div className="count">3</div>
                  <span className="date">09 Jan 2020</span>
                </div>
                <div className="mb-4">
                  <p>Case Study: HeroSports.com</p>
                  <h3>Scalable Infrastructure & Capability</h3>
                </div>
                <span className="country">Canada</span>
              </div>
            </Col>
            <Col md="4">
              <div className="study-card icon-2" onClick={() => toggle1(2)}>
                <div className="d-flex justify-content-between m_b_4">
                  <div className="count">2</div>
                  <span className="date">22 Feb 2018</span>
                </div>
                <div className="mb-4">
                  <p>Case Study: Almatar.com</p>
                  <h3>
                    Automation <br /> and <br /> Architecture
                  </h3>
                </div>
                <span className="country">Canada</span>
              </div>
            </Col>
          </Flickity>
        </div>
      </div>
      */}
      <div className="section-four wow fadeInUp" id="services">
        <div className="container-fluid">
          <h3 className="title" data-wow-duration="2s">
            Services
          </h3>
          <Flickity
            className={"services-slider"}
            data-wow-duration="2s"
            elementType={"div"}
            options={flickityOptions1}
          >
            <Col md="4">
              <div className="service-card" onClick={() => toggle1(4)}>
                <img
                  src="/assets/images/service-card-1.png"
                  className="img-fluid w-100"
                  alt="service card"
                  width="420"
                  height="628"
                />
                <div className="service-content">
                  <h3>Cloud Migration</h3>
                  <p>
                    Get the right solution of Cloud to take your organisation
                    into the Next Generation of Operations. Migrate hassel free.
                  </p>
                  <Link to="/" className="link">
                    Know More
                  </Link>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="service-card" onClick={() => toggle1(5)}>
                <img
                  src="/assets/images/service-card-2.png"
                  className="img-fluid w-100"
                  alt="service card"
                  width="420"
                  height="628"
                />
                <div className="service-content">
                  <h3>Dev Environments </h3>
                  <p>
                    Bring the world of developers work for your project, under
                    one roof - without creating a chaos of codes & dependencies
                  </p>
                  <Link to="/" className="link">
                    Know More
                  </Link>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="service-card" onClick={() => toggle1(6)}>
                <img
                  src="/assets/images/service-card-3.png"
                  className="img-fluid w-100"
                  alt="service card"
                  width="420"
                  height="628"
                />
                <div className="service-content">
                  <h3>Release Engineering</h3>
                  <p>
                    Automate your development pipelines with an ease of
                    operations and enable rapid iterations.
                  </p>
                  <Link to="/" className="link">
                    Know More
                  </Link>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="service-card" onClick={() => toggle1(7)}>
                <img
                  src="/assets/images/service-card-1.png"
                  className="img-fluid w-100"
                  alt="service card"
                  width="420"
                  height="628"
                />
                <div className="service-content">
                  <h3>Automated Deployments</h3>
                  <p>
                    Do not let your company suffer with downtimes, now deploy
                    your software with a click and set your timeline
                    accordingly.
                  </p>
                  <Link to="/" className="link">
                    Know More
                  </Link>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="service-card" onClick={() => toggle1(8)}>
                <img
                  src="/assets/images/service-card-2.png"
                  className="img-fluid w-100"
                  alt="service card"
                  width="420"
                  height="628"
                />
                <div className="service-content">
                  <h3>Site Reliability Engineering </h3>
                  <p>
                    Let you and your organization take care of the product and
                    leave the mechanism monitoring on CloudDrove
                  </p>
                  <Link to="/" className="link">
                    Know More
                  </Link>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="service-card" onClick={() => toggle1(9)}>
                <img
                  src="/assets/images/service-card-3.png"
                  className="img-fluid w-100"
                  alt="service card"
                  width="420"
                  height="628"
                />
                <div className="service-content">
                  <h3>Load Testing</h3>
                  <p>
                    A good business will never expect unpleasant surprises on
                    the day of Traffic influx. Stay prepared in advance.
                  </p>
                  <Link to="/" className="link">
                    Know More
                  </Link>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="service-card" onClick={() => toggle1(10)}>
                <img
                  src="/assets/images/service-card-1.png"
                  className="img-fluid w-100"
                  alt="service card"
                  width="420"
                  height="628"
                />
                <div className="service-content">
                  <h3>Security and Compliance</h3>
                  <p>
                    Websites are the e-office of yours, Protect it with the most
                    secure encryption and infrastructure to your website.
                  </p>
                  <Link to="/" className="link">
                    Know More
                  </Link>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="service-card" onClick={() => toggle1(11)}>
                <img
                  src="/assets/images/service-card-2.png"
                  className="img-fluid w-100"
                  alt="service card"
                  width="420"
                  height="628"
                />
                <div className="service-content">
                  <h3>Gap Assessments</h3>
                  <p>
                    Bringing the best teams together should not be a terrifying
                    task any more. Even the different technical disciplines can.
                  </p>
                  <Link to="/" className="link">
                    Know More
                  </Link>
                </div>
              </div>
            </Col>
          </Flickity>
        </div>
      </div>
      {/* <div className="cloud-drove-strip wow fadeInUp">
        <div className="container d-flex-start " data-wow-duration="2s">
          <span>
            CLOUD<b>DROVE</b>
          </span>
          <span className="year">2021</span>
        </div>
      </div> */}
      <div className="section-five wow fadeInUp">
        <div className="container-fluid">
          <h3 className="title">Our Knowledge Stack</h3>
          <div className="clients">
            <ul className="list-inline d-flex-arround before-border">
              <li
                className="list-inline-item wow fadeInLeft"
                data-wow-delay="0.1s"
              >
                <Link to="/">
                  <img
                    src="/assets/images/terraform-logo.png"
                    className="img-fluid"
                    alt="client icon"
                    width="250"
                    height="55"
                  />
                </Link>
              </li>
              <li
                className="list-inline-item wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <Link to="/">
                  <img
                    src="/assets/images/Kubernetes_Logo.png"
                    className="img-fluid"
                    alt="client icon"
                    width="250"
                    height="38"
                  />
                </Link>
              </li>
              <li
                className="list-inline-item wow fadeInLeft"
                data-wow-delay="0.5s"
              >
                <Link to="/">
                  <img
                    src="/assets/images/docker-logo.png"
                    className="img-fluid"
                    alt="client icon"
                    width="170"
                    height="41"
                  />
                </Link>
              </li>
              <li
                className="list-inline-item wow fadeInLeft"
                data-wow-delay="0.7s"
              >
                <Link to="/">
                  <img
                    src="/assets/images/Ansible-logo.png"
                    className="img-fluid"
                    alt="client icon"
                    width="250"
                    height="29"
                  />
                </Link>
              </li>
            </ul>
          </div>
          {startup && (
            <Row>
              <Col md="6" className="wow fadeInLeft" data-wow-duration="2s">
                <img
                  src={baseURL + startup?.image}
                  className="img-fluid w-100"
                  alt="book"
                  width="613"
                  height="766"
                />
              </Col>
              <Col
                md="5"
                className="offset-md-1 align-self-center wow fadeInRight"
                data-wow-duration="2s"
              >
                <div className="content">
                  <h3>{startup?.title}</h3>
                  <p>{startup?.description}</p>
                  <button
                    className="download-btn"
                    onClick={() =>
                      downloadPDF(baseURL + startup?.pdf, "e-book")
                    }
                  >
                    <span>Download Now</span> <i className="fa fa-download"></i>
                  </button>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <div className="section-six">
        <div className="container-fluid">
          <Row className="d-flex">
            <Col md="4" className="wow fadeInUp" data-wow-duration="2s">
              <div className="content">
                <h3>Publications</h3>
                <p>
                  Read about our latest insights on cloud and all the technology
                  surrounding it through detailed articles and ebooks. Learn
                  about the most relevant trends on issues that matter most in
                  the software development business.
                </p>
                <Link
                  to={{ pathname: "https://blog.clouddrove.com" }}
                  className="btn-gradient-primary"
                  target="_blank"
                >
                  Read More
                </Link>
              </div>
            </Col>
            <Col
              md="8"
              className="pos-right wow fadeInRight"
              data-wow-duration="2s"
            >
              <Flickity
                className={"blog-slider"}
                elementType={"div"}
                options={flickityOptions2}
              >
                {blogs.map((item, index) => {
                  return (
                    <Col md="6" key={index}>
                      <div className="blog-card">
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <img
                            src={baseURL + item.image}
                            className="img-fluid w-100"
                            alt="blog fig"
                            width=""
                            height=""
                          />
                        </a>
                        <div className="blog-content">
                          <h3>{item.title}</h3>
                          <p>
                            {item.description.length > 220
                              ? `${item.description.slice(0, 220 - 1)}...`
                              : item.description}
                          </p>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Flickity>
            </Col>
          </Row>
        </div>
      </div>
      {/* <div className="section-seven" id="contact">
				<div className="container-fluid">
					<Row>
						<Col md="5" className="align-self-center wow fadeInLeft" data-wow-duration="2s">
							<div className="contact-content">
								<h2>THE<br />TECH<br />ARTISANS</h2>
							</div>
						</Col>
						<Col md="7">
							<div className="p-2 bg-success toaster-box" hidden={!show}>
								<Toast isOpen={show}>
									<ToastBody>
										<p className="mb-0">Thank You! We will contact you soon!</p>
										<Link onClick={toggletoast}>X</Link>
									</ToastBody>

								</Toast>
							</div>
							<div className="contact-box wow fadeInRight" data-wow-duration="2s">
								<h2>Let's Connect</h2>
								<ul className="list-unstyled">
									{Object.entries(formErrors || {}).map(([prop, value]) => {
										return (
											<li className='text-danger fw-bold' key={prop}>
												{value}
											</li>
										);
									})}
								</ul>
								<Form onSubmit={handleconnectSubmit}>
									<FormGroup>
										<Input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
									</FormGroup>
									<FormGroup>
										<Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Work email address" />
									</FormGroup>
									<FormGroup>
										<Input type="text" value={companyname} onChange={(e) => setCompanyname(e.target.value)} placeholder="Company Name" />
									</FormGroup>
									<div className="d-flex justify-content-end mt-5">
										<Button type="submit" className="submit-btn">SUBMIT</Button>
									</div>
								</Form>
							</div>
						</Col>
					</Row>
				</div>
			</div> */}
      <Modal isOpen={modal} toggle={toggle1} className="service_popup">
        <ModalBody>
          <h5 className="text-center">Services</h5>
          <Media className="mb-4">
            <Media left top>
              <div className="img_box">
                <Media
                  object
                  src={clientimagecasestudy}
                  alt="Generic placeholder image"
                  width="100"
                  height="100"
                />
              </div>
              <h5>{clientnamecasestudy}</h5>
            </Media>
            <Media body className="scrollbar">
              <p dangerouslySetInnerHTML={{ __html: modaltextcasestudy }}></p>

              {/* <p>
								Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
								Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
							</p>
							<p>
								Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
								Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
							</p> */}
            </Media>
          </Media>
          <div className="flex-end">
            <Button color="primary" onClick={toggle1}>
              Close
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Home;
