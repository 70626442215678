import React, { useState } from "react";
import { Col, Button, Media, Modal, ModalBody } from "reactstrap";
import Flickity from "react-flickity-component";

const HowWework = () => {
  const [modaltextcasestudy, setModaltext] = useState("");
  const [clientnamecasestudy, setClientname] = useState("");
  const [clientimagecasestudy, setClientimage] = useState("");
  const [modal, setModal] = useState(false);

  //For Modal
  function toggle1(modelumber) {
    switch (modelumber) {
      case "ManagingLife":
        setModaltext(
          "<b>Project requirements</b> <br/> Our client was facing several critical challenges in their infrastructure. As the pace of business increased, these challenges were no longer sustainable. To meet the demands and gain scalability, reduced disruption, and data delivery, speed was key. They needed to modernize their infrastructure. <br/> <br/> <b>Solution: </b><br/>CloudDrove team dockerize the application and moved the app from AWS EC2 to Kubernetes. Our team was responsible for setting up the build and deployment automation with Terraform/Ansible. We set up CI/CD with Jenkins and make use of a wide range of plugins available to set up smooth developer friendly workflows.			Utilized Grafana & Prometheus to monitor the performance environment instances for operational and performance metrics during load testing.	We supported them to manage Compliance and Audit of ISO 27001, SOC 2 standards"
        );
        setClientname("ManagingLife");
        setClientimage("/assets/images/ManagingLife.png");
        break;
      case "Almatar":
        setModaltext(
          "<b>Project Requirements</b><br/> Our client was looking for an expert to design the architecture and lead their DevOps team. The current system was facing a lot of issues due to zero automation (everything was manual and very unstable). They wanted to move to a better infrastructure with Kubernetes and create handle 10K users per min. <br/><br/>	<b>Solution:</b> <br/> CloudDrove solution architects prepared a highly available & secure AWS architecture using the AWS well-architectured framework. We prepared MGT & EKS clusters by using terraform for migrating infrastructure to Kubernetes Jenkins, monitoring, logging deployed on MGT Cluster, which is managed and accessed by DevOps.	Our team set up a fully automated CI/CD by using GitLab & Jenkins which also built scan and push images to ECR, and deployed on multiple environments based on the requirements	Infrastructure pipelines were set up using gitops mythology Migrated 30+ microservices to AWS EKS Setup 24*7 monitoring & Alerting system Cloud Drove NOC Team (L1) offered 24*7 support	<br/><br/> <b>Result: </b> <br/> The website is smooth, cost-effective, autoscaled based on traffic, and can easily have 10K users per min Management of level 1 alerts made DevOps life easier Deployment became easier and 3 times faster, currently they are able to deploy more than 5 times a day"
        );
        setClientname("Almatar");
        setClientimage("/assets/images/Almatar.png");
        break;
      case "HeroSports":
        setModaltext(
          "<b>Project Requirements</b><br/> Herosport is US based start-up and after gaining a significant place in the market they approached us with a requirement of a more scalable infrastructure with high-load capability. The expected volume was 7000-10000 users/ per minute. <br/><br/>	<b>Solution:</b> <br/> As CloudDrove we planned and designed an architecture on AWS. Made the app containerized by Docker. Deployed the app with Hashi Crop and Terraform. Used Hashi corp Packer to create and manage the AMI's and automated the whole AMI creation process using Jenkins jobs. Built scripts for scheduling EC2 auto-scaling, so the infrastructure was capable of using from 1 virtual machine to 3 depending on the user volume.	<br/><br/> <b>Result: </b> <br/> HeroSport increased its product reliability and scalability, while lowering its operating costs. Now it can easily perform up to 15K users per minute."
        );
        setClientname("HeroSports");
        setClientimage("/assets/images/HeroSports.png");
        break;
      default:
    }
    setModal(!modal);
  }

  const flickityOptions = {
    initialIndex: 1,
    wrapAround: true,
    prevNextButtons: false,
    autoPlay: 3000,
    cellAlign: "center",
    resize: true,
    pauseAutoPlayOnHover: true,
  };

  // const flickityOptions1 = {
  //   initialIndex: 1,
  //   wrapAround: true,
  //   prevNextButtons: false,
  //   autoPlay: true,
  //   cellAlign: "left",
  //   resize: true,
  // };

  // const flickityOptions2 = {
  //   wrapAround: true,
  //   prevNextButtons: false,
  //   pageDots: false,
  //   autoPlay: 3000,
  //   cellAlign: "left",
  //   resize: true,
  // };

  return (
    <>
      <div className="hwoitwork_banner">
        <img
          src="/assets/images/how_banner.jpg"
          className="img-fluid w-100"
          alt="how we work banner"
          width=""
          height=""
        />
        <div className="container-fluid">
          <div className="how-work-header wow fadeInRight">
            <h3>
              At CloudDrove we offer you, quality work with a competitive cost
              and fast solutions
            </h3>
            <p className="header-pragraph">
              We provide services in different time zones, with a team of a
              think-tank, full of ideas and energy to assist you
            </p>
          </div>
        </div>
      </div>
      <div className="howwe-work">
        <div className="driection-dotted"></div>
        <div className="getIn_touch">
          <div className="container-fluid d-between">
            <div className="know_us">
              <h2>
                How <br />
                We Work?
              </h2>
            </div>
          </div>
        </div>

        <div className="our-process-section">
          <div className="container-fluid">
            <div className="section_title wow fadeInUp">
              <h2>Our Process</h2>
            </div>

            <div className="our-process">
              <div className="row">
                <div className="process-detial wow fadeInRight">
                  <h3>Explore</h3>
                  <p>
                    Gain a deeper understanding of your business by interviewing
                    your stakeholders, since no one understands it better than
                    you do. Your product is handed over to our team, which is
                    then inspected through different parameters to bring out the
                    current status of the product
                  </p>
                </div>
                <div className="img-box wow fadeInLeft">
                  <img
                    src="/assets/images/discover.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="our-process audit_suggestions">
              <div className="row">
                <div className="process-detial wow fadeInLeft">
                  <h3>Audit + Suggestions</h3>
                  <p>
                    A DevOps software implementation is successful when it is
                    built on an integrated set of tools which removes manual
                    steps, reduces errors, increases team agility, and scales
                    beyond small teams. And we make sure that your product comes
                    up with the same mechanism
                  </p>
                </div>
                <div className="img-box wow fadeInRight">
                  <img
                    src="/assets/images/audit_suggestions.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="our-process">
              <div className="row">
                <div className="process-detial wow fadeInRight">
                  <h3>Architecture</h3>
                  <p>
                    We develop the architecture in collaboration with your
                    CTO/VPE and engineering teams to support current business
                    goals and future strategic initiatives. In order to enhance
                    engineers’ operational skills, we put a strong emphasis on
                    cross-training from the beginning
                  </p>
                </div>
                <div className="img-box wow fadeInLeft">
                  <img
                    src="/assets/images/build_Architecture.png"
                    className="img-fluid "
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="our-process optimize">
              <div className="row">
                <div className="process-detial wow fadeInLeft">
                  <h3>Optimize</h3>
                  <p>
                    We follow the DevOps agile practices and latest tools and
                    technologies to streamline the development process by
                    automating key steps, increasing operational efficiency and
                    standardising processes
                  </p>
                </div>
                <div className="img-box wow fadeInRight">
                  <img
                    src="/assets/images/optimize.png"
                    className="img-fluid "
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="our-process maintain">
              <div className="row">
                <div className="process-detial wow fadeInLeft">
                  <h3>Maintenance</h3>
                  <p>
                    As part of our continued investment in the success of your
                    company, we stay engaged for as long as it takes. Ensure
                    that everything is functioning properly with periodic audits
                    and reviews. A budget-friendly approach to cloud computing
                    is imperative. Therefore we implement cost controls and make
                    forecasts of cloud expenditures
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="what-we-offer section-black-full wow fadeInUp">
          <div className="container-fluid d-between">
            <h3 wow fadeInLeft>
              What we offer
            </h3>
            <div className="what-offer wow fadeInUp">
              <div className="ecosystem wow fadeInLeft">
                <p>
                  We dont offer just a <b>solution</b>
                </p>
                <p className="f-52">
                  We create an <b>ecosystem</b>
                </p>
              </div>
              <div className="find-out">
                <div className="find-box bg-white wow fadeInUp">
                  <h5>Architecture</h5>
                  <p>
                    Several organisations have had their infrastructures
                    successfully modernised by us. Be a part of this change and
                    upgrade today
                  </p>
                </div>
                <div className="find-box bg-white wow fadeInUp">
                  <h5>Proof of Concept</h5>
                  <p>
                    As hands-on experts in the cloud native space, we help
                    organisations design and maintain their infrastructure
                  </p>
                </div>
                <div className="find-box bg-white wow fadeInUp">
                  <h5>Automation</h5>
                  <p>
                    Since the advent of cloud computing, we have been
                    successfully automating several traditional software
                    development processes
                  </p>
                </div>
                <div className="find-box bg-white wow fadeInUp">
                  <h5>Optimisation</h5>
                  <p>
                    We have Introduced mechanisms that facilitate agility
                    through standardized processes to eliminate performance
                    bottlenecks
                  </p>
                </div>
                <div className="find-box wow fadeInUp">
                  <a href="about#contact" className="find-out-btn">
                    Find out more on a call
                  </a>
                </div>
                <div className="find-box bg-white wow fadeInUp">
                  <h5>24x7 Support</h5>
                  <p>
                    We love working with customers to continually improve their
                    products and operational processes, at your convenience of
                    timings
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-three inner-casestudy wow fadeInUp">
          <div className="container-fluid">
            <div className="section-title wow fadeInUp" data-wow-duration="2s">
              <span className="border-gray"></span>
              <h3>Case Studies</h3>
              <p>Few of our celebrated case studies</p>
            </div>
          </div>

          <div className="casestudy-block wow fadeInUp">
            <Flickity
              className={"caseStudy-slider"}
              data-wow-duration="0s"
              elementType={"div"}
              options={flickityOptions}
              disableImagesLoaded={false}
              reloadOnUpdate
              static
            >
              <Col md="4">
                <div
                  className="study-card icon-2"
                  onClick={() => toggle1("Almatar")}
                >
                  <img src="/assets/images/case-study-1.png" alt="" />
                </div>
              </Col>
              <Col md="4">
                <div
                  className="study-card icon-1"
                  onClick={() => toggle1("HeroSports")}
                >
                  <img src="/assets/images/case-study-2.png" alt="" />
                </div>
              </Col>
              <Col md="4">
                <div
                  className="study-card icon-3"
                  onClick={() => toggle1("ManagingLife")}
                >
                  <img src="/assets/images/case-study-3.png" alt="" />
                </div>
              </Col>
            </Flickity>
          </div>
        </div>
      </div>

      <div className="find-library">
        <div className="container-fluid">
          <h2>Find our Treasured Library</h2>
          <span className="roboto-img">
            <img src="/assets/images/robot.png" alt="" />
          </span>

          <div className="cloud-github">
            <span className="git-img">
              <img src="/assets/images/oval.png" alt="" />
            </span>
            <span className="cloudgit">CloudDrove GitHub Library</span>
            <span className="spacing-cloud"></span>
            <a
              className="btn-gradient-primary nav-link"
              href="https://github.com/clouddrove/toc"
              target="_blank"
              rel="noreferrer"
            >
              Show Library
            </a>
          </div>
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle1} className="service_popup">
        <ModalBody>
          <h5 className="text-center">Services</h5>
          <Media className="mb-4">
            <Media left top>
              <div className="img_box">
                <Media
                  object
                  src={clientimagecasestudy}
                  alt="Generic placeholder image"
                  width="100"
                  height="100"
                />
              </div>
              <h5>{clientnamecasestudy}</h5>
            </Media>
            <Media body className="scrollbar">
              <p dangerouslySetInnerHTML={{ __html: modaltextcasestudy }}></p>
            </Media>
          </Media>
          <div className="flex-end">
            <Button color="primary" onClick={toggle1}>
              Close
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default HowWework;
