import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
} from "reactstrap";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  let thePath = window.location.href;
  const location = useLocation();
  const [pathname, setPathname] = useState(
    thePath.substring(thePath.lastIndexOf("/") + 1)
  );

  useEffect(() => {
    setPathname(window.location.pathname);
  }, [location]);

  return (
    <Navbar light expand="md" className="fixed-top main-header">
      <Container fluid={true}>
        <NavbarBrand href="/">
          <img
            src="/assets/images/logo.png"
            className="img-fluid"
            alt="site logo"
            width="120"
            height="46"
          />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ms-auto" navbar>
            <NavItem className={`${pathname === "#services" ? "active" : ""}`}>
              <NavLink href="/#services">Services</NavLink>
            </NavItem>
            <NavItem className={`${pathname === "about" ? "active" : ""}`}>
              <NavLink href="/about">About Us</NavLink>
            </NavItem>
            <NavItem
              className={`${pathname === "how-we-work" ? "active" : ""}`}
            >
              <NavLink href="/how-we-work">How We Work</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://blog.clouddrove.com/" target="_blank">
                Blogs
              </NavLink>
            </NavItem>
            <NavItem className={`${pathname === "career" ? "active" : ""}`}>
              <NavLink href="/career">Career</NavLink>
            </NavItem>
            <NavItem
              className={`${pathname === "about#contact" ? "active" : ""}`}
            >
              <NavLink href="/about#contact">Contact</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
