import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { getJobsBySlugAPI } from "./../../services/user";
import SocialShare from "./../../components/SocialShare";

const JobDetail = () => {
  const { slug } = useParams();
  const history = useHistory();
  const [jobs, setJobs] = useState(null);

  useEffect(() => {
    const loadJobsBySlug = async () => {
      try {
        const resp = await getJobsBySlugAPI(slug);
        setJobs(resp.data.response || null);
      } catch (err) {
        const { response } = err;
        console.log("err=>", response);
      }
    };
    loadJobsBySlug();
  }, [slug]);

  const handleCopy = () => {
    window.navigator.clipboard.writeText(window.location.href);
    toast.success("Copy successfully");
  };

  const moveToJobApply = () => {
    return history.push(`/job-apply/${slug}`);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{jobs?.title}</title>
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={jobs?.title} />
        <meta property="og:description" content={jobs?.title} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="job-details">
        <div className="after-header">
          <div className="container-fluid">
            <div className="after-header-content">
              <h1>
                CLOUD<strong>DROVE</strong>
              </h1>
              <span className="right-text">{jobs?.title}</span>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="job-header">
            <h2>{jobs?.title}</h2>
            {jobs && (
              <ul className="breadcamb">
                <li>{jobs?.jobLocation}</li>
              </ul>
            )}

            <div className="apply-now-action">
              <Button
                onClick={() => moveToJobApply()}
                className="apply-now-btn"
              >
                Apply Now{" "}
                <span className="icon">
                  <i className="fa  fa-long-arrow-right"></i>
                </span>
              </Button>
            </div>
          </div>

          <div className="job-detail-middle">
            <div className="job-description">
              <p
                dangerouslySetInnerHTML={{
                  __html: jobs?.description,
                }}
              ></p>
              <div className="apply-now-action">
                <Button
                  onClick={() => moveToJobApply()}
                  className="apply-now-btn"
                >
                  Apply Now
                  <span className="icon">
                    <i className="fa  fa-long-arrow-right"></i>
                  </span>
                </Button>
              </div>
            </div>

            <div className="right-sidebar">
              <div className="sidebar-img">
                <img
                  src="/assets/images/career-header-bg.jpg"
                  className="img-fluid w-100"
                  alt="career banner"
                  width=""
                  height=""
                />
              </div>

              <div className="key-job-detail">
                <h3>Key Job Details</h3>
                <ul>
                  {/* <li>
                    Country/Region: <span>{jobs?.country}</span>
                  </li>
                  <li>
                    State: <span>{jobs?.state}</span>
                  </li>
                  <li>
                    City: <span>{jobs?.city}</span>
                  </li> */}
                  <li>
                    Category: <span>{jobs?.category?.name}</span>
                  </li>
                  <li>
                    Required Education: <span>{jobs?.education}</span>
                  </li>
                  <li>
                    Job Location: <span>{jobs?.jobLocation}</span>
                  </li>
                  {/* <li>
                    Position Type: <span>{jobs?.positionType}</span>
                  </li>
                  <li>
                    Employment Type: <span>{jobs?.empType}</span>
                  </li>
                  <li>
                    Contract Type: <span>{jobs?.contractType}</span>
                  </li>
                  <li>
                    Travel Required: <span>{jobs?.travel}</span>
                  </li> */}
                </ul>

                <ul className="social-shareing">
                  <label>Share</label>
                  <SocialShare url={window.location.href} title={jobs?.title} />
                  {/* <li>
                    <FacebookShareButton
                      url={window.location.href}
                      quote={jobs?.title}
                      hashtag={"#jobs"}
                      className="social-icon fb"
                    >
                      <FacebookIcon size={36} />
                    </FacebookShareButton>
                  </li> */}
                  {/* <li>
                    <TwitterShareButton
                      url={window.location.href}
                      title={jobs?.title}
                      hashtags={["jobs"]}
                      className="social-icon twitter"
                    >
                      <TwitterIcon size={36} />
                    </TwitterShareButton>
                  </li> */}
                  {/* <li>
                    <LinkedinShareButton
                      url={`https://www.linkedin.com/sharing/share-offsite?url=${window.location.href}`}
                      title={jobs?.title}
                      hashtags={["jobs"]}
                      className="social-icon linkedin"
                      source={window.location.href}
                    >
                      <LinkedinIcon size={36} />
                    </LinkedinShareButton>
                  </li> */}
                  <li>
                    <button
                      className="social-icon more"
                      onClick={() => handleCopy()}
                    >
                      <i className="fa  fa-clone"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="job-detial-footer">
          <div className="container-fluid">
            <h4>Become the Official Tech Artisan</h4>
          </div>
        </div>
      </div>
      {/* 
      <Modal isOpen={modal} className="career_popup">
        <ModalBody>
          <div className="flex-end">
            <a
              className="close-modal"
              color="primary"
              onClick={() => setModal(!modal)}
            >
              <img src="/assets/images/close_icon.png" />
            </a>
          </div>

          <div className="career-popup-content">
            <h1 className="text-center">Our Terms</h1>
            <p className="sub-heading text-center">
              Please read our full terms & conditions before applying
            </p>

            <Media body className="scrollbar">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Viverra nibh cras pulvinar mattis nunc. Proin sagittis nisl
                rhoncus mattis rhoncus urna neque. Sem nulla pharetra diam sit
                amet nisl suscipit adipiscing. Eleifend quam adipiscing vitae
                proin sagittis. Pellentesque sit amet porttitor eget. Dictum non
                consectetur a erat nam. Massa id neque aliquam vestibulum morbi
                blandit cursus. Quis imperdiet massa tincidunt nunc pulvinar
                sapien et ligula ullamcorper. Posuere urna nec tincidunt
                praesent semper feugiat nibh sed pulvinar.
              </p>

              <p>
                Et netus et malesuada fames ac turpis egestas integer. Quam
                pellentesque nec nam aliquam sem et tortor consequat. Diam
                phasellus vestibulum lorem sed risus ultricies. Nunc consequat
                interdum varius sit amet mattis vulputate enim. Sem fringilla ut
                morbi tincidunt. Non odio euismod lacinia at quis risus sed
                vulputate. Sed libero enim sed faucibus turpis in eu mi
                bibendum. Aliquam eleifend mi in nulla posuere sollicitudin
                aliquam ultrices. Sed arcu non odio euismod lacinia at.
                Sollicitudin aliquam ultrices sagittis orci a scelerisque purus.
                Enim sed faucibus turpis in eu mi bibendum neque.
              </p>

              <p>
                Id semper risus in hendrerit gravida rutrum quisque non. Semper
                eget duis at tellus at urna condimentum mattis pellentesque.
                Elementum integer enim neque volutpat. Vel risus commodo viverra
                maecenas accumsan. Et leo duis ut diam. Ultrices neque ornare
                aenean euismod elementum nisi quis. Ultricies integer quis
                auctor elit sed vulputate mi. Sit amet massa vitae tortor
                condimentum lacinia quis vel. Scelerisque viverra mauris in
                aliquam sem fringilla ut morbi. Sem et tortor consequat id. Mi
                bibendum neque egestas congue. Fermentum posuere urna nec
                tincidunt praesent semper feugiat nibh. Velit dignissim sodales
                ut eu sem integer vitae. Facilisi etiam dignissim diam quis enim
                lobortis scelerisque fermentum. Vestibulum lorem sed risus
                ultricies tristique nulla aliquet enim tortor.
              </p>
              <p>
                Porttitor rhoncus dolor purus non enim. Nec ullamcorper sit amet
                risus nullam eget felis eget. Vitae turpis massa sed elementum
                tempus egestas sed. Aenean pharetra magna ac placerat. Eget nunc
                scelerisque viverra mauris in aliquam sem. Mauris augue neque
                gravida in fermentum. Phasellus egestas tellus rutrum tellus
                pellentesque eu. Eleifend donec pretium vulputate sapien nec
                sagittis aliquam malesuada. Pretium aenean pharetra magna ac
                placerat vestibulum lectus mauris. Feugiat vivamus at augue eget
                arcu dictum varius duis. A scelerisque purus semper eget duis at
                tellus at. Arcu risus quis varius quam quisque. Netus et
                malesuada fames ac. Habitasse platea dictumst vestibulum rhoncus
                est. Bibendum at varius vel pharetra vel.
              </p>

              <p>
                Sodales neque sodales ut etiam sit amet nisl purus. Neque
                sodales ut etiam sit amet nisl purus. Faucibus ornare
                suspendisse sed nisi lacus sed viverra tellus. A scelerisque
                purus semper eget duis at. In vitae turpis massa sed elementum.
                Velit sed ullamcorper morbi tincidunt ornare massa eget.
                Suspendisse faucibus interdum posuere lorem ipsum dolor. Neque
                laoreet suspendisse interdum consectetur libero id. Viverra nibh
                cras pulvinar mattis nunc. Ornare suspendisse sed nisi lacus sed
                viverra tellus in. Phasellus faucibus scelerisque eleifend donec
                pretium. Gravida neque convallis a cras.
              </p>
            </Media>
          </div>

          <div className="agree-btn-full">
            <a href="#" className="agree-btn disabled none">
              I Agree
            </a>
            <Link to={`/job-apply/${jobs?._id}`} className="agree-btn">
              I Agree
            </Link>

            <p>
              <strong>
                By applying for this job, you agree to the full terms of the
                above legal statement
              </strong>
            </p>
          </div>
        </ModalBody>
      </Modal>
   */}
    </>
  );
};
export default JobDetail;
