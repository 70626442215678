import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, FormGroup, Input, Button } from "reactstrap";
import Flickity from "react-flickity-component";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHACODE, ADMINURL } from "../../config";
import { createMeetingApi, contactMsgToSlackAPI } from "./../../services/user";
import { toast } from "react-toastify";
import moment from "moment";

const About = () => {
  const fields = {
    name: "",
    email: "",
    description: "",
  };
  const [values, setValues] = useState(fields);
  const [errors, setErrors] = useState(fields);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const recaptchaRef = useRef(null);

  const flickityOptions = {
    initialIndex: 1,
    wrapAround: true,
    prevNextButtons: false,
    pageDots: false,
    autoPlay: 1000,
    cellAlign: "center",
    resize: true,
  };

  useEffect(() => {
    handleValidate(values);
  }, [values]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const sendMsgToSlack = async (_payload) => {
    const { _id, name, email, description, createdAt } = _payload?.response;
    const payload = {
      attachments: [
        {
          color: "#f2c744",
          blocks: [
            {
              type: "header",
              text: {
                type: "plain_text",
                text: "Metting Request",
                emoji: true,
              },
            },
            {
              type: "divider",
            },
            {
              type: "section",
              fields: [
                {
                  type: "mrkdwn",
                  text: `*Name:* ${name}`,
                },
              ],
            },
            {
              type: "section",
              fields: [
                {
                  type: "mrkdwn",
                  text: `*Email:* ${email}`,
                },
              ],
            },
            {
              type: "section",
              fields: [
                {
                  type: "mrkdwn",
                  text: `*Description:* ${description}`,
                },
              ],
            },
            {
              type: "section",
              fields: [
                {
                  type: "mrkdwn",
                  text: `*Date:* ${moment(createdAt).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}`,
                },
              ],
            },
            {
              type: "actions",
              elements: [
                {
                  type: "button",
                  text: {
                    type: "plain_text",
                    emoji: true,
                    text: "View Meeting Request",
                  },
                  url: `${ADMINURL}/app/meeting/view/${_id}`,
                  style: "primary",
                  value: "click_to_view",
                  action_id: "Pressed",
                },
              ],
            },
          ],
        },
      ],
    };
    try {
      const resp = await contactMsgToSlackAPI(payload);
      console.log("data=>", resp);
    } catch (err) {
      const { response } = err;
      console.log("err=>", response);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    const recaptchaValue = recaptchaRef.current?.getValue();
    if (!recaptchaValue) {
      return;
    }
    if (handleValidate(values)) {
      setLoading(true);
      try {
        const resp = await createMeetingApi(values);
        const result = await sendMsgToSlack(resp?.data);
        console.log("resp", resp, "result", result);
        setValues(fields);
        setErrors(fields);
        setSubmitted(false);
        setLoading(false);
        toast.success(resp?.data?.message);
      } catch (err) {
        const { response } = err;
        console.log("err=>", response);
        setLoading(false);
        toast.error(response?.data?.message);
      }
    }
  };

  const handleValidate = (values) => {
    let errors = {};
    let isValid = true;
    if (!values["name"]) {
      isValid = false;
      errors["name"] = "Name is required.";
    }
    if (typeof values["email"] !== "undefined") {
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(values["email"])) {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }
    if (!values["email"]) {
      isValid = false;
      errors["email"] = "Email is required";
    }
    if (!values["description"]) {
      isValid = false;
      errors["description"] = "Description is required";
    }
    setErrors(errors);
    return isValid;
  };

  return (
    <>
      <div className="about-banner">
        <img
          src="/assets/images/about-banner.png"
          className="img-fluid w-100"
          alt="about banner"
          width=""
          height=""
        />
        <div className="container-fluid">
          <div className="about-content">
            <h5>The inception &</h5>
            <h3>
              The Backstage of <br />
              Clouddrove
            </h3>
            <h5 className="text-blue">About Us</h5>
          </div>
        </div>
      </div>
      <div className="getIn_touch">
        <div className="container-fluid d-between">
          <div className="know_us">
            <h2>
              Get In <br />
              Touch <br />
              With US{" "}
            </h2>
            <ul className="list-inline">
              <li className="list-inline-item">
                <Link to="/">
                  <i className="fa fa-instagram"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/">
                  <i className="fa fa-facebook-square"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/">
                  <i className="fa fa-twitter-square"></i>
                </Link>
              </li>
            </ul>
          </div>
          <div className="address-box">
            <h2>CloudDrove Studios</h2>
            <address>
              <span>18, King Street East, Suite 1400</span>
              <span>Toronto ON, M5C1C4</span>
              <span>
                <b>Email us:</b>hello@clouddrove.com
              </span>
            </address>
          </div>
          <div className="des-box">
            <h3>Your organisation's essential framework</h3>
            <p>
              If your enterprise is on the path to a digital transformation, you
              simply cannot miss DevOps. It is instrumental in achieving a
              high-performing business when adopted the right way.
            </p>
          </div>
        </div>
      </div>
      <div className="title-bar">
        <div className="container-fluid">{/* <h3>lorumipsum</h3> */}</div>
      </div>
      <div className="aboutUs_wrap">
        <div className="container-fluid">
          <div className="getTo_know row g-0">
            <div className="Know_us col-md-4">
              <div className="position-relative">
                <div className="gray-box"></div>
                <h2>
                  Get to know <br />
                  us better
                </h2>
              </div>
              {/* <div className="media  wow fadeInUp">
                <img
                  src="/assets/images/harman.png"
                  className="img-fluid rounded-circle"
                  alt="user profile"
                  width="70"
                  height="70"
                />
                <div className="media-body">
                  <h4>Harman Kaur</h4>
                  <h6>CEO, CloudDrove</h6>
                  <p className="mb-0">
                    Takes care of the team, so there is no delay
                  </p>
                </div>
              </div>
              <div className="media  wow fadeInUp">
                <img
                  src="/assets/images/anmol.png"
                  className="img-fluid rounded-circle"
                  alt="user profile"
                  width="70"
                  height="70"
                />
                <div className="media-body">
                  <h4>Anmol Nagpal</h4>
                  <h6>Senior Solutions Architect</h6>
                  <p className="mb-0">
                    Takes care of infra, so there is no gap
                  </p>
                </div>
              </div> */}
            </div>
            <div className="about_des col-md-8  wow fadeInUp">
              <div className="mb-4">
                <p className="crop">
                  An organisation that fits like LEGO. You can pick us and start
                  building your castle from day 0. CloudDrove has a remarkable
                  footprint in European and American Corridors. We already have
                  our presence in 11 countries. We aim to make CloudDrove a Plug
                  & Play Engine Machinists.
                </p>
              </div>
              <div className="mb-4 ps-6">
                <p className="ps-4-5">
                  We have deployed our team in different timezones, so that we
                  can create an ecosystem that can serve our clients around the
                  world, as per their schedule. With us, organisations from
                  startups to enterprise level can optimise their IT front into
                  a robust-fluid system, that not only caters the consumer base
                  but also helps the backend engineers in many ways. Being the
                  captain of your company, you must not be hindered by the
                  delays that go into pushing a product on web. That should be
                  the least of your concern and CloudDrove helps you in
                  achieving a 360 degree infrastructure solution. To summarise
                  our mission, you can say that your entire IT front
                  driving-machine is just a call away. So the next time when
                  your product is ready, focus on the world, not on your engine.
                </p>
              </div>
              {/* <div className="mb-4 ps-6">
                <p className="ps-4-5">
                  Harmanjyot Kaur and Anmol Nagpal started this company with a
                  basic idea and that is, Let’s Design, Deploy, and Manage your
                  cloud, On Demand.
                </p>
              </div> */}
            </div>
          </div>
        </div>
        <div className="time-map">
          <div className="time-map-section container-fluid">
            <h3 className="team-tilte">Our Journey</h3>
            <div className="time-map-box">
              <div className="mapBoxinner">
                <div className="time-date-mob wow fadeInRight">
                  <img
                    className="img-fluid"
                    src="/assets/images/date-mob-1.png"
                    alt=""
                  />
                </div>
                <div className="map-box wow fadeInLeft">
                  <span className="back-in-harman">
                    Back in 2018, Harman and Anmol started this company with a
                    basic idea and that is, Lets Design, Deploy, and Manage your
                    cloud, On Demand.
                  </span>
                  <span className="year">2018</span>
                </div>
                <div className="time-date wow fadeInRight">
                  <img
                    className="img-fluid"
                    src="/assets/images/date-1.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="mapBoxinner">
                <div className="time-date time-date-left"></div>
                <div className="map-box map-box-right wow fadeInRight">
                  <span className="year">2019</span>
                  <span className="back-in-harman">
                    We delivered our first project in Germany with our offices
                    in sundry Bars and Lounges
                  </span>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="mapBoxinner">
                <div className="time-date-mob wow fadeInRight">
                  <img
                    className="img-fluid"
                    src="/assets/images/date-mob-2.png"
                    alt=""
                  />
                </div>
                <div className="map-box wow fadeInLeft">
                  <span className="back-in-harman">
                    Consequently, with projects pouring in, our old colleagues
                    joined hands with us in 2020 and formed an official
                    organization named CloudDrove
                  </span>
                  <span className="year">2020</span>
                </div>
                <div className="time-date wow fadeInRight">
                  <img
                    className="img-fluid"
                    src="/assets/images/date-2.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="mapBoxinner">
                <div className="time-date-mob wow fadeInRight">
                  <img
                    className="img-fluid"
                    src="/assets/images/date-mob-3.png"
                    alt=""
                  />
                </div>
                <div className="time-date time-date-left wow fadeInLeft">
                  <img
                    className="img-fluid"
                    src="/assets/images/date-3.png"
                    alt=""
                  />
                </div>
                <div className="map-box  map-box-right wow fadeInRight">
                  <span className="year">2021</span>
                  <span className="back-in-harman">
                    Since then, we have kept on walking on the shores of
                    different countries and have grown ever since
                  </span>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="wedidtogether wow fadeInUp">
        <div className="container-fluid">
          <div className="wedidtogether-window">
            <div className="heading-bg">
              <div className="action-btn-box">
                <img src="/assets/images/window-action.png" alt="" />
              </div>
              We did it together
            </div>
            <div className="window-content">
              <div className="col-md-4">
                <h3>
                  2<br />
                  Years
                </h3>
                <p>
                  Our diverse clientele ranges from start-ups to enterprises
                  with our footprints in 6 continents. To cater all the time
                  zones, we keep adding new talents to our team
                </p>
              </div>

              <div className="col-md-4">
                <h3>
                  100+
                  <br />
                  Projects
                </h3>
                <p>
                  We have managed to build secure, reliable, and scalable IT
                  infrastructure with cost optimization, automation and 24 hour
                  support to our clients
                </p>
              </div>

              <div className="col-md-4">
                <h3>
                  100%
                  <br />
                  Success
                </h3>
                <p>
                  Our roaring success came from various recognitions like UpWork
                  & Clutch. With each project delivered on time, we made sure to
                  achieve Client Satisfaction
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="section-five-one">
        <div className="container-fluid">
          <div className="section-title">
            <span className="border-gray"></span>
            <h3>Our Clients</h3>
            <p>
              Tincidunt tortir aliqume nulla <br /> Facilisi cras fermentum.
            </p>
          </div>
        </div>
        <div className="our-client-logo">
          <Flickity
            className={"client-slider"}
            elementType={"div"}
            options={flickityOptions}
          >
            <div className="wrap">
              <div className="cust-card">
                <div className="cust-image">
                  <img
                    src="/assets/images/apx-mart.png"
                    className="img-fluid"
                    alt="apx-mart"
                    width="219"
                    height="84"
                  />
                </div>
              </div>
            </div>
            <div className="wrap">
              <div className="cust-card">
                <div className="cust-image">
                  <img
                    src="/assets/images/amp.png"
                    className="img-fluid"
                    alt="amp"
                    width="120"
                    height="84"
                  />
                </div>
              </div>
            </div>
            <div className="wrap">
              <div className="cust-card">
                <div className="cust-image">
                  <img
                    src="/assets/images/managing.png"
                    className="img-fluid"
                    alt="Managing life"
                    width="160"
                    height="84"
                  />
                </div>
              </div>
            </div>
            <div className="wrap">
              <div className="cust-card">
                <div className="cust-image">
                  <img
                    src="/assets/images/urbanfinder.png"
                    className="img-fluid"
                    alt="Urban finder"
                    width="180"
                    height="84"
                  />
                </div>
              </div>
            </div>
            <div className="wrap">
              <div className="cust-card">
                <div className="cust-image">
                  <img
                    src="/assets/images/wise-publishing.png"
                    className="img-fluid"
                    alt="wise Publishing"
                    width="140"
                    height="84"
                  />
                </div>
              </div>
            </div>
            <div className="wrap">
              <div className="cust-card">
                <div className="cust-image">
                  <img
                    src="/assets/images/Indra.png"
                    className="img-fluid"
                    alt="Indra Crypto Capital"
                    width="150"
                    height="84"
                  />
                </div>
              </div>
            </div>
            <div className="wrap">
              <div className="cust-card">
                <div className="cust-image">
                  <img
                    src="/assets/images/sweetspot.png"
                    className="img-fluid"
                    alt="sweetspot"
                    width="250"
                    height="84"
                  />
                </div>
              </div>
            </div>
            <div className="wrap">
              <div className="cust-card">
                <div className="cust-image">
                  <img
                    src="/assets/images/j.png"
                    className="img-fluid"
                    alt="Managing life"
                    width="260"
                    height="84"
                  />
                </div>
              </div>
            </div>
            <div className="wrap">
              <div className="cust-card">
                <div className="cust-image">
                  <img
                    src="/assets/images/k.png"
                    className="img-fluid"
                    alt="Urban finder"
                    width="180"
                    height="84"
                  />
                </div>
              </div>
            </div>
            <div className="wrap">
              <div className="cust-card">
                <div className="cust-image">
                  <img
                    src="/assets/images/bsh-tech.png"
                    className="img-fluid"
                    alt="BSH TECH"
                    width="140"
                    height="84"
                  />
                </div>
              </div>
            </div>
          </Flickity>
        </div>
      </div>
      <div className="our_work wow fadeInUp">
        <div className="container-fluid">
          <div className="section_title wow fadeInUp">
            <h2>Our Presence</h2>
            <p>We are serving across the world</p>
          </div>
          <div className="locationmap-img wow fadeInUp">
            <img
              src="../assets/images/map_location.jpeg"
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="time-map">
        <div className="container-fluid text-center">
          <div
            className="clutch-widget"
            data-url="https://widget.clutch.co"
            data-widget-type="8"
            data-expandifr="true"
            data-height="auto"
            data-clutchcompany-id="970216"
          ></div>
        </div>
        <div id="contact"></div>
      </div>

      <div className="container-fluid">
        <div className="book_meet">
          <div className="section_title">
            <h2>Set up a meeting?</h2>
          </div>
          <div className="meeting-card">
            <Row>
              <Col md="4">
                <div className="blue-card d-center">
                  <div className="content">
                    <h2>
                      Book a <br />
                      Meeting
                    </h2>
                    <p>
                      Ready to switch to cloud solutions for your business? Talk
                      to our experts to turn your ideas into reality.
                    </p>
                    {/* <Link to="/" className="btn-light btn">Whats App me</Link> */}
                  </div>
                </div>
              </Col>
              <Col md="7" className="offset-md-1 align-self-center">
                <div className="contact-block">
                  <h3>
                    Lets discuss the pain points and see <br />
                    how can we solve the issue with <br />
                    our generation’s best solutions
                  </h3>
                  <ul className="list-unstyled">
                    {submitted &&
                      Object.entries(errors || {}).map(([prop, value]) => {
                        return (
                          <li className="text-danger fw-bold" key={prop}>
                            {value}
                          </li>
                        );
                      })}
                  </ul>
                  <Form onSubmit={handleSubmit}>
                    <div className="contact-us-form">
                      <FormGroup>
                        <Input
                          name="name"
                          type="text"
                          value={values.name}
                          onChange={handleChange}
                          placeholder="Name"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input
                          name="email"
                          type="text"
                          value={values.email}
                          onChange={handleChange}
                          placeholder="Email Address"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input
                          name="description"
                          type="text"
                          value={values.description}
                          onChange={handleChange}
                          placeholder="Description"
                        />
                      </FormGroup>
                    </div>
                    <ReCAPTCHA ref={recaptchaRef} sitekey={CAPTCHACODE} />
                    <div className="d-flex justify-content-end mt-5">
                      <Button
                        type="submit"
                        disabled={loading}
                        className="submit-btn"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
export default About;
