import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { getJobCategoryAPI, getJobsAPI } from "./../../services/user";

const Career = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [jobsCategory, setJobsCategory] = useState([]);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    loadJobCategory();
    loadJobs();
  }, []);

  const loadJobCategory = async () => {
    try {
      const resp = await getJobCategoryAPI();
      setJobsCategory(resp.data.response || []);
    } catch (err) {
      const { response } = err;
      console.log("err=>", response);
    }
  };

  const loadJobs = async () => {
    try {
      const resp = await getJobsAPI();
      setJobs(resp.data.response || []);
    } catch (err) {
      const { response } = err;
      console.log("err=>", response);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <div className="career-banner">
        <div className="overlay-bg"></div>
        <img
          src="/assets/images/career-header-bg.jpg"
          className="img-fluid w-100"
          alt="career banner"
          width=""
          height=""
        />
        <div className="container-fluid">
          <div className="carrer-content wow fadeInLeft">
            <h3>
              JOIN <br />
              OUR
              <br /> TEAM
            </h3>
            <p className="header-pragraph">
              At CloudDrove, the world’s most talented Devops engineers are
              shaping the future of web architecture
            </p>
          </div>
        </div>
      </div>
      <div className="career-top-text wow fadeInUp" id="career-top-text">
        <div className="container-fluid d-between">
          <div className="open-position-left">
            <h2>Open Positions</h2>
            <p>
              We’re looking for people to join our team who are as excited as we
              are, to help build the platform that empowers the future
              generation of creators to be successful.
            </p>
          </div>
        </div>

        <div className="container-fluid d-between">
          <div className="jobs-department wow fadeInUp">
            <h3>Select Department</h3>

            <div className="department-tabs">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "all" })}
                    onClick={() => {
                      toggle("all");
                    }}
                  >
                    All
                  </NavLink>
                </NavItem>
                {jobsCategory.map((item, index) => {
                  return (
                    <NavItem key={index}>
                      <NavLink
                        className={classnames({
                          active: activeTab === item.name,
                        })}
                        onClick={() => {
                          toggle(item.name);
                        }}
                      >
                        {item.name}
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>

              <TabContent activeTab={activeTab}>
                {jobs.map((item, index) => {
                  return (
                    <TabPane key={index} tabId={"all"}>
                      <div className="tab-card-content">
                        <div className="table-view">
                          <h3>{item.title}</h3>
                          <span className="count-bold">{item.position}</span>
                          <Link to={`/career/${item.slug}`}>Apply</Link>
                        </div>
                      </div>
                    </TabPane>
                  );
                })}
                {jobs.map((item, index) => {
                  return (
                    <TabPane key={index} tabId={item.category?.name}>
                      <div className="tab-card-content">
                        <div className="table-view">
                          <h3>{item.title}</h3>
                          <span className="count-bold">{item.position}</span>
                          <Link to={`/career/${item.slug}`}>Apply</Link>
                        </div>
                      </div>
                    </TabPane>
                  );
                })}
              </TabContent>
            </div>
            <p className="bold-text mt-4">
              All the listed jobs are active and are governed in a realtime. Any
              time you post an application, it goes through a series of internal
              screenings. Dont worry, every application gets a revert, so that
              you stay informed about the status. Happy Applying.
            </p>
          </div>
        </div>
      </div>

      <div className="section-black-full">
        <div className="container-fluid d-between">
          <h3>Perks & benefits</h3>

          <div className="preks-benefits-section wow fadeInUp">
            <img
              src="/assets/images/benifits.jpg"
              className="img-fluid w-100"
              alt="benefits banner"
              width=""
              height=""
            />

            <div className="content-text">
              <div className="col-6 align-self-center wow fadeInUp">
                <h4>Relax, we’ve got you covered.</h4>

                <p>
                  Self education and learning is an integral part of a career
                  and at CloudDrove, we respect education and knowledge.
                  Whenever and where ever you go, if there is an oppurtunity of
                  learning something new, and you want to grab it, let us know.
                  We will make sure that the our company budget sustains it, so
                  that you can learn something new.{" "}
                </p>
              </div>

              <div className="col-6 last align-self-center wow fadeInUp">
                <h4>Flexible Timing</h4>

                <p>
                  We believe very strongly on one thing, and that is, “Work that
                  Works”. Choose your preferred time of work and simply deliver
                  the project that you own here, rest No Questions Asked Policy
                  will let you to do the job at your convinience. That’s the
                  secret sauce of our organization.
                </p>
              </div>
            </div>

            <div className="content-text">
              <div className="col-6 align-self-center wow fadeInUp">
                <h4>Responsibility from Day 1</h4>

                <p>
                  When you join us, you become the owner of your projects. With
                  experts guiding you when you need it, start a journey to
                  explore the new possibilities of DevOps.{" "}
                </p>
              </div>

              <div className="col-6 last align-self-center wow fadeInUp">
                <h4>Enjoy working remotely</h4>

                <p>
                  All you need is a Wifi and a system that lets you connect with
                  the world. Rest it does not matter if you are in the Alps or
                  at a beach. Get the freedom of choosing your workstation from
                  anywhere in the world, and start working with us!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="our-team-main">
        <div className="our-team-section">
          <div className="container-fluid">
            <h3>Our Team</h3>

            <div className="team-member">
              <div className="team-member-text wow fadeInLeft">
                <h2>
                  An organization <br />
                  that develops and <br />
                  <span>nurtures you</span>
                </h2>
                <p>
                  I have been working in CloudDrove almost 2 years, besides the
                  good working conditions I appreciate their approach to their
                  employees which makes you feel as a family member. They are
                  showing everytime respect and trust on you. Always interested
                  with also your possible personal problems which makes you feel
                  more motivated to provide them the quality work they are
                  looking for.
                </p>
                <span className="position-job">
                  <span className="position-box">
                    Business Development Manager
                  </span>
                </span>
              </div>

              <div className="team-member-img relative wow fadeInRight">
                <div className="member-pic">
                  <img src="/assets/images/kseniia_ozgel.jpg" />
                  <span className="user-name">
                    Kseniia Özgel | &nbsp;<b>Business Development Manager</b>
                  </span>
                </div>
                <div className="employees-quote wow fadeInUp">
                  <h4>“The Master Chef of Business”</h4>
                  <p>
                    Kensia is our Business Development Manager. We love the way
                    she dedicates her services and experiences towards our
                    organization.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="our-team-section alternative-section">
          <div className="container-fluid">
            <h3>Our Team</h3>

            <div className="team-member">
              <div className="team-member-text wow fadeInRight">
                <h2>
                  CloudDrove is the address that
                  <br /> <span>stresses on quality</span>
                </h2>
                <p>
                  Why do we work? So that we can pay our bills, and where should
                  we work? Some place that’s not polluted and scrambled.
                  CloudDrove is the address that stresses on quality and
                  productivity, not on employees. With a very clear vision of
                  the founders, the job becomes very easy, and certainly it
                  brings the entire team together, towards a single goal.
                </p>
                <span className="position-job">
                  <span className="position-box">
                    Head of Digital Marketing
                  </span>
                </span>
              </div>

              <div className="team-member-img relative wow fadeInLeft">
                <div className="member-pic">
                  <img src="/assets/images/upamanyu.jpeg" />
                  <span className="user-name">
                    Upamanyu Sushma Naskar | &nbsp;
                    <b>Head of Digital Marketing</b>
                  </span>
                </div>
                <div className="employees-quote wow fadeInUp">
                  <h4>“The Mickey of Disney ”</h4>
                  <p>
                    If our visuals are saying more than words, then he is the
                    story teller of everything that we digitally represent
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="our-team-section">
          <div className="container-fluid">
            <h3>Our Team</h3>

            <div className="team-member">
              <div className="team-member-text wow fadeInLeft">
                <h2>
                  The respect & <br />
                  the trust they show,
                  <br />
                  <span>is motivating</span>
                </h2>
                <p>
                  This is amazing for me to be here with genius guys. All team
                  members are very supportive and everyone is dedicated to their
                  work. There are many new things to learn and management has
                  given me a free hand to improve the things. I have been here
                  about a 2 months now, and I have not heard any grumbling about
                  management or the company or even the co-workers. I love it!
                </p>
              
              </div>

              <div className="team-member-img relative wow fadeInRight">
                <div className="member-pic">
                  <img src="/assets/images/om_sharma.jpg" />
                  <span className="user-name">
                    Om Sharma | &nbsp;<b>DevOps Engineer</b>
                  </span>
                </div>
                <div className="employees-quote wow fadeInUp">
                  <h4>“The Pathfinder of CloudDrove”</h4>
                  <p>
                    Om Sharma is our senior Devops Engineer and the path finder
                    of our organization who makes our way to every solution that
                    our clients seeks for
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="our-team-section alternative-section">
          <div className="container-fluid">
            <h3>Our Team</h3>

            <div className="team-member">
              <div className="team-member-text wow fadeInLeft">
                <h2>
                  An organization <br />
                  that develops and <br />
                  <span>nurtures you</span>
                </h2>
                <p>
                  I have been in CloudDrove since the beginning. Not only I have
                  grown substantially but also learnt how to work with different
                  clients and teams. The exposure is good, the management takes
                  a good note of your suggestions and the scope of growth is
                  pretty good.
                </p>
                <span className="position-job">
                  <span className="position-box">Sr. DevOps Engineer</span>
                </span>
              </div>

              <div className="team-member-img relative wow fadeInRIght">
                <div className="member-pic">
                  <img src="/assets/images/nikita_dugar.jpg" />
                  <span className="user-name">
                    Nikita Dugar | &nbsp;<b>Sr. DevOps Engineer</b>
                  </span>
                </div>
                <div className="employees-quote wow fadeInUp">
                  <h4>“Our Moana, who make projects possible”</h4>
                  <p>
                    With a deapth of observations and surrounded by technology,
                    Nikita is not only a great leader but also a trouble saviour
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="our-team-section">
          <div className="container-fluid">
            <h3>Our Team</h3>

            <div className="team-member">
              <div className="team-member-text wow fadeInRight">
                <h2>
                  The advantage to
                  <br /> grow in skills and <span>knowledge</span>
                </h2>
                <p>
                  After more than 3 years in the organization, I can
                  categorically say that it’s been full of learning experiences.
                  CloudDrove is one of the most promising growth companies. It
                  provides a whole bunch of opportunities to anyone in the
                  organization especially for those who have the drive for it.
                  The most important thing that I would appreciate is the
                  learning that comes with those opportunities that fuelled my
                  learning appetite.
                </p>
                <span className="position-job">
                  <span className="position-box">Sr. DevOps Engineer</span>
                </span>
              </div>

              <div className="team-member-img relative wow fadeInLeft">
                <div className="member-pic">
                  <img src="/assets/images/sohan_yadav.jpg" />
                  <span className="user-name">
                    Sohan Yadav | &nbsp;<b>Sr. DevOps Engineer</b>
                  </span>
                </div>
                <div className="employees-quote wow fadeInUp">
                  <h4>“The engineer with a Pulse Detector”</h4>
                  <p>
                    Sohan is a Sr. DevOps Engineer AKA Jason Statham of our
                    team. Calm, Composed and a killer engineer
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="our-team-section alternative-section">
          <div className="container-fluid">
            <h3>Our Team</h3>

            <div className="team-member">
              <div className="team-member-text wow fadeInRight">
                <h2>
                  Opportunities to <br />
                  <span>learn and teach</span>
                </h2>
                <p>
                  Since the last 6 months, I am working in CloudDrove. It is a
                  very well-known company. We can say CloudDrove is an
                  employee-friendly company. The superiors are very cooperative
                  with the subordinates. CloudDrove gives you open opportunities
                  to learn and teach. The salary offered is really decent. The
                  working environment was really joyful. It gave me a 100% job
                  guarantee with satisfaction. I joined as a DevOps
                  administrator now I'm a DevOps engineer.
                </p>
                <span className="position-job">
                  <span className="position-box">DevOps Engineer</span>
                </span>
              </div>

              <div className="team-member-img relative wow fadeInLeft">
                <div className="member-pic">
                  <img src="/assets/images/manish_shah.jpg" />
                  <span className="user-name">
                    Manish Shah | &nbsp;<b>DevOps Engineer</b>
                  </span>
                </div>
                <div className="employees-quote wow fadeInUp">
                  <h4>“The Terminator of DevOps</h4>
                  <p>
                    Manish is our DevOps Engineer, and like his skills he is
                    also hard coded to his ethics, hardwork and precised
                    calculations.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="our-team-section">
          <div className="container-fluid">
            <h3>Our Team</h3>

            <div className="team-member">
              <div className="team-member-text wow fadeInRight">
                <h2>
                  Clear vision of
                  <br /> <span>the founders</span>
                </h2>
                <p>
                  Working for CloudDrove is nothing less than an academic
                  experience. Not only is the work culture is fun, but also a
                  great boost that a career needs. If not for everyone, but for
                  every individual who likes to work for a greater good result,
                  CloudDrove appreciates it in the most rewarding way.
                </p>
                <span className="position-job">
                  <span className="position-box">DevOps Engineer</span>
                </span>
              </div>

              <div className="team-member-img relative wow fadeInLeft">
                <div className="member-pic">
                  <img src="/assets/images/prakash.jpg" />
                  <span className="user-name">
                    Prakash Yadav | &nbsp;<b>DevOps Engineer</b>
                  </span>
                </div>
                <div className="employees-quote wow fadeInUp">
                  <h4>“Our own barney of Flintstones”</h4>
                  <p>
                    With Prakash in the team, none of our projects ever go out
                    of sight. He makes sure every code is there, where it is
                    required.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="our-team-section alternative-section">
          <div className="container-fluid">
            <h3>Our Team</h3>

            <div className="team-member">
              <div className="team-member-text wow fadeInRight">
                <h2>
                  Accommodating & <br />
                  encouraging <span>workplace</span>
                </h2>
                <p>
                  I am very happy to work here as an accountant starting in
                  2020. I feel all things over here: excellent working culture,
                  friendly, learning as well as growth and management is very
                  supportive , it’s also one of the best companies. Company
                  policies are good. Overall I enjoy a lot while working with
                  the team.
                </p>
                <span className="position-job">
                  <span className="position-box">Accountant</span>
                </span>
              </div>

              <div className="team-member-img relative wow fadeInLeft">
                <div className="member-pic">
                  <img src="/assets/images/sujeet.jpg" />
                  <span className="user-name">
                    Sujeet Kumar Sharma | &nbsp;<b> Accountant</b>
                  </span>
                </div>
                <div className="employees-quote wow fadeInUp">
                  <h4>“Hawkeye of CloudDrove Universe ”</h4>
                  <p>
                    With our Hawkeye in the team, our every penny stays
                    accounted for. Our secret weapon and reason for expansion.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="find-job">
        <div className="container-fluid">
          <div className="find-job-inner wow fadeInUp">
            <h5>OUR VALUES</h5>
            <h2>
              We Believe in <br />
              <span>“Work that Works”</span>
            </h2>
            <a href="#career-top-text" className="find-jobs">
              Find Jobs
            </a>
          </div>
        </div>
      </div>

      <div className="clearboth"></div>
    </>
  );
};
export default Career;
