import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Form, FormGroup, Input, Button, Modal, ModalBody } from "reactstrap";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import { baseURL, ADMINURL } from "../../config";
import {
  getJobsBySlugAPI,
  createJobApplyAPI,
  jobMsgToSlackAPI,
} from "../../services/user";
import { jsonToFormData } from "../../utils";
import moment from "moment";

const JobApply = () => {
  const fields = {
    file: "",
    fullName: "",
    email: "",
    phone: "",
    company: "",
    linkedIn: "",
    gitHub: "",
    description: "",
  };
  const { slug } = useParams();
  const history = useHistory();
  const [jobs, setJobs] = useState(null);
  const [values, setValues] = useState(fields);
  const [errors, setErrors] = useState(fields);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  //For Modal
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const loadJobsBySlug = async () => {
      try {
        const resp = await getJobsBySlugAPI(slug);
        setJobs(resp.data.response || null);
      } catch (err) {
        const { response } = err;
        console.log("err=>", response);
      }
    };
    loadJobsBySlug();
  }, [slug]);

  useEffect(() => {
    handleValidate(values);
  }, [values]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handlePhoneChange = (value) => {
    if (values) {
      setValues({
        ...values,
        phone: value,
      });
    }
  };

  const handleFileInput = (event) => {
    const file = event.target.files[0];
    setValues({
      ...values,
      file: file,
    });
  };

  const sendMsgToSlack = async (_payload) => {
    const { _id, fullName, email, phone, company, resume, createdAt } =
      _payload?.response;
    const payload = {
      attachments: [
        {
          color: "#f2c744",
          blocks: [
            {
              type: "header",
              text: {
                type: "plain_text",
                text: "Job Request",
                emoji: true,
              },
            },
            {
              type: "divider",
            },
            {
              type: "section",
              fields: [
                {
                  type: "mrkdwn",
                  text: `*Name:* ${fullName}`,
                },
              ],
            },
            {
              type: "section",
              fields: [
                {
                  type: "mrkdwn",
                  text: `*Email:* ${email}`,
                },
              ],
            },
            {
              type: "section",
              fields: [
                {
                  type: "mrkdwn",
                  text: `*Phone:* ${phone}`,
                },
              ],
            },
            {
              type: "section",
              fields: [
                {
                  type: "mrkdwn",
                  text: `*Company:* ${company}`,
                },
              ],
            },
            {
              type: "section",
              fields: [
                {
                  type: "mrkdwn",
                  text: `*Date:* ${moment(createdAt).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}`,
                },
              ],
            },
            {
              type: "section",
              fields: [
                {
                  type: "mrkdwn",
                  text: `*Resume:* ${baseURL + resume}`,
                },
              ],
            },
            {
              type: "actions",
              elements: [
                {
                  type: "button",
                  text: {
                    type: "plain_text",
                    emoji: true,
                    text: "View Job Request",
                  },
                  url: `${ADMINURL}/app/jobs-details/view/${_id}`,
                  style: "primary",
                  value: "click_to_view",
                  action_id: "Pressed",
                },
              ],
            },
          ],
        },
      ],
    };
    try {
      const resp = await jobMsgToSlackAPI(payload);
      console.log("data=>", resp);
    } catch (err) {
      const { response } = err;
      console.log("err=>", response);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (handleValidate(values)) {
      setLoading(true);
      const objAssign = { job: jobs?._id, ...values };
      const payload = jsonToFormData(objAssign);
      try {
        const resp = await createJobApplyAPI(payload);
        const result = await sendMsgToSlack(resp?.data);
        console.log("data=>", resp, "result", result);
        setValues(fields);
        setErrors(fields);
        setSubmitted(false);
        setLoading(false);
        setModal(!modal);
      } catch (err) {
        const { response } = err;
        console.log("err=>", response);
        setLoading(false);
        toast.error(response?.data?.message);
      }
    }
  };

  const handleValidate = (values) => {
    let errors = {};
    let isValid = true;
    if (!values["file"]) {
      isValid = false;
      errors["file"] = "Resume is required.";
    }
    if (!values["fullName"]) {
      isValid = false;
      errors["fullName"] = "Full name is required.";
    }
    if (typeof values["email"] !== "undefined") {
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(values["email"])) {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }
    if (!values["email"]) {
      isValid = false;
      errors["email"] = "Email is required";
    }
    if (typeof values["phone"] !== "undefined") {
      if (values["phone"].length < 13 || values["phone"].length > 15) {
        isValid = false;
        errors["phone"] = "Please enter valid mobile number";
      }
    }
    if (!values["phone"]) {
      isValid = false;
      errors["phone"] = "Phone is required";
    }
    if (!values["company"]) {
      isValid = false;
      errors["company"] = "Company is required";
    }
    setErrors(errors);
    return isValid;
  };

  return (
    <>
      <div className="job-details">
        <div className="after-header">
          <div className="container-fluid">
            <div className="after-header-content">
              <h1>
                CLOUD<strong>DROVE</strong>
              </h1>
              <span className="right-text">{jobs?.title}</span>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          {jobs && (
            <div className="job-header">
              <h2>{jobs.title}</h2>
              <ul className="breadcamb">
                <li>{jobs.jobLocation}</li>
              </ul>
            </div>
          )}

          <div className="job-apply-form">
            <h3>SUBMIT YOUR APPLICATION</h3>

            <Form onSubmit={handleSubmit}>
              <div className="apply-job-from">
                <FormGroup>
                  <label>
                    Upload Your Resume <sup>*</sup>
                  </label>
                  <Input
                    className="fileuploader"
                    type="file"
                    onChange={handleFileInput}
                    accept="application/msword,application/pdf"
                  />
                </FormGroup>
                {submitted && !!errors.file && (
                  <div className="inline-errormsg">{errors.file}</div>
                )}

                <FormGroup>
                  <label>
                    Full Name <sup>*</sup>
                  </label>
                  <Input
                    name="fullName"
                    type="text"
                    value={values.fullName}
                    onChange={handleChange}
                    placeholder="John Mc Stannin"
                  />
                </FormGroup>
                {submitted && !!errors.fullName && (
                  <div className="inline-errormsg">{errors.fullName}</div>
                )}

                <FormGroup>
                  <label>
                    Email <sup>*</sup>
                  </label>
                  <Input
                    name="email"
                    type="text"
                    value={values.email}
                    onChange={handleChange}
                    placeholder="john@gmail.com"
                  />
                </FormGroup>
                {submitted && !!errors.email && (
                  <div className="inline-errormsg">{errors.email}</div>
                )}

                <FormGroup className="phone-no">
                  <label>
                    Phone <sup>*</sup>
                  </label>
                  <PhoneInput
                    defaultCountry="IN"
                    value={values.phone}
                    onChange={handlePhoneChange}
                    placeholder="78887 35199"
                  />
                </FormGroup>
                {submitted && !!errors.phone && (
                  <div className="inline-errormsg">{errors.phone}</div>
                )}

                <FormGroup>
                  <label>
                    Current Company <sup>*</sup>
                  </label>
                  <Input
                    name="company"
                    type="text"
                    value={values.company}
                    onChange={handleChange}
                    placeholder="IBM"
                  />
                </FormGroup>
                {submitted && !!errors.company && (
                  <div className="inline-errormsg">{errors.company}</div>
                )}
              </div>

              <div className="apply-job-from">
                <h6 className="more-link">Links</h6>
                <FormGroup>
                  <label>LinkedIn</label>
                  <Input
                    name="linkedIn"
                    type="text"
                    value={values.linkedIn}
                    onChange={handleChange}
                    placeholder="https://www.linkedin.com/in/username/"
                  />
                </FormGroup>

                <FormGroup>
                  <label>GitHub</label>
                  <Input
                    name="gitHub"
                    type="text"
                    value={values.gitHub}
                    onChange={handleChange}
                    placeholder="@githubuser"
                  />
                </FormGroup>

                <FormGroup>
                  <label>What makes you awesome</label>
                  <textarea
                    className="textarea"
                    name="description"
                    rows="4"
                    cols="50"
                    value={values.description}
                    onChange={handleChange}
                    placeholder="Mention the area of your expertise? How you approach a problem and tend to find a solution. Why we should be hiring you, and what value would you add to CloudDrove?"
                  ></textarea>
                </FormGroup>
              </div>
              <div className="dividor"></div>
              <div className="d-flex justify-content-center mb-6">
                <Button
                  type="submit"
                  disabled={loading}
                  className="apply-now-btn"
                >
                  Submit
                  <span className="icon">
                    <i className="fa  fa-long-arrow-right"></i>
                  </span>
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <div className="job-detial-footer job-footer-dark">
          <div className="container-fluid">
            <h4>Become the Official Tech Artisan</h4>
          </div>
        </div>
      </div>

      <Modal isOpen={modal} className="career_popup sucess-popup">
        <ModalBody>
          <div className="career-popup-content">
            <div className="oval">
              <div className="oval-inner"></div>
            </div>
            <h1 className="text-center">Awesome!</h1>
            <p>
              You have successfully submitted your CV. We will get back to you
              very soon
            </p>
          </div>
          <div className="fd-flex justify-content-center mt-5">
            <button
              className="apply-now-btn"
              color="primary"
              onClick={() => {
                setModal(!modal);
                history.push("/career");
              }}
            >
              Ok
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default JobApply;
